import React, { useEffect, useRef, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Layout from "../components/Layout";

const subscribeWeekly = [
    {
        name: "£10",
        subId: "P-68W58672C3466574TMXBKBUA"
    },
    {
        name: "£15",
        subId: "P-80A81554JJ827715EMXPYMNA"
    },
    {
        name: "£20",
        subId: "P-2MY4378126657601YMXPYTRA"
    },
    {
        name: "£30",
        subId: "P-8XL097988W9715742MXPYUUA"
    }
]

const subscribeMonthly = [
    {
        name:"£10",
        subId: "P-9863415135712263MMXPYV2A"
    },
    {
        name: "£15",
        subId: "P-3RH444574H715080XMXPYWWQ"
    },
    {
        name: "£20",
        subId: "P-94L05719WN0261908MXPYXUI"
    },
    {
        name: "£30",
        subId: "P-9RV043174D001062VMXPYYWY"
    }
]

const PayPalSubButton = ({ planid }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_APP_CLIENT_ID}&vault=true&intent=subscription`;
        script.async = true;
        script.onload = () => {
            setIsLoaded(true);
        };
    
        document.body.appendChild(script);
    
        return () => {
            if (document.body.contains(script)&&isLoaded===false) {
                document.body.removeChild(script); // Remove script when component unmounts
            } else {
                console.log("script not found");
            }
        };
    }, []);
    
    
    

    if (!isLoaded) return null;

    return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_APP_CLIENT_ID, vault: true }}>
            <div id={`paypal-button-container-${planid}`}>
                <PayPalButtons
                    style={{
                        shape: 'rect',
                        color: 'gold',
                        layout: 'vertical',
                        label: 'subscribe'
                    }}
                    createSubscription={(data, actions) => {
                        return actions.subscription.create({
                            plan_id: `${planid}`
                        });
                    }}
                    onApprove={(data, actions) => {
                        alert(data.subscriptionID); // You can add optional success message for the subscriber here
                    }}
                />
            </div>
        </PayPalScriptProvider>
    );
};

const Give = () => {
    const [paymentFrequency, setPaymentFrequency] = useState("One-off") 
    const [amount, setAmount] = useState("1.00")
    const [showPaypal, setShowPaypal] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false);
    const [subId, setSubId] = useState("")

    const handleScriptLoad = () => {
        setIsLoaded(true);
    };

    const paymentOptions = [
        "One-off",
        "Weekly",
        "Monthly"
    ]

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                description: "Cool looking table",
                amount: {
                    currency_code: "GBP", // Correct currency code
                    value: amount // Correct field name
                }
            }]
        });
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then((details) => {
            const { payer } = details;
                alert(`Transaction completed by ${payer.name.given_name}!`);
                // Implement additional logic here (e.g., updating order status in your database)
        });
    };

    const onError = (err) => {
        console.error("PayPal Checkout onError", err);
        // Implement additional error handling logic here
    };

    const handleDonationChange = (event) => {
        setAmount(event.target.value)
        setShowPaypal(false)
        console.log(amount)
    };

    const handleFrequencyChange = (frequency) =>{
        setPaymentFrequency(frequency)
        setShowPaypal(false)
    }

    const handleDonate = () =>{
        setShowPaypal(true)
    }

    return (
        <Layout page="Give online">
            <div className="items-center justify-center grid grid-cols-1 gap-12">
                <div className="flex flex-row gap-4 rounded-xl items-center justify-center">
                    {paymentOptions.map((option) => (
                        <button
                            key={option}
                            className={`h-16 w-28 rounded-xl border-8 border-white px-2 font-mono font-bold ${paymentFrequency === option ? `text-blue-800 font-bold bg-white ` : `text-white `}`}
                            onClick={() => handleFrequencyChange(option)}
                        >
                            {option}
                        </button>
                    ))}
                </div>

                {paymentFrequency === "Monthly" ? 
                    (showPaypal
                        ? 
                        <div className='flex flex-col gap-4 rounded-xl items-center justify-center'>
                            <div className='flex flex-row gap-4 rounded-xl items-center justify-center'>
                                <PayPalSubButton planid={subId}/>
                            
                            </div>
                            <button 
                                className='h-16 w-28 rounded-xl border-8 border-blue-500 px-2 font-mono font-bold text-white'
                                onClick={()=>{setShowPaypal(false)}}
                            > 
                                Back 
                            </button>
                        </div>
                        
                        
                        :
                        
                        <div className='flex flex-row gap-4 rounded-xl items-center justify-center'>
                            
                        {
                            
                            subscribeMonthly.map((sub)=>{
                                return(
                                    <button 
                                        key={sub.subId}
                                        className='h-16 w-28 rounded-xl border-8 border-white px-2 font-mono font-bold text-white'
                                        onClick={()=>{
                                            setSubId(sub.subId)
                                            setShowPaypal(true)
                                        }}
                                        >
                                        {sub.name}
                                    </button>
                                )
                            })
                        }

                        </div>
                    )
                : <></>
                }

                {paymentFrequency === "Weekly" ? 
                    (showPaypal
                        ? 
                        <div className='flex flex-col gap-4 rounded-xl items-center justify-center'>
                            <div className='flex flex-row gap-4 rounded-xl items-center justify-center'>
                                <PayPalSubButton planid={subId}/>
                            
                            </div>
                            <button 
                                className='h-16 w-28 rounded-xl border-8 border-blue-500 px-2 font-mono font-bold text-white'
                                onClick={()=>{setShowPaypal(false)}}
                            > 
                                Back 
                            </button>
                        </div>
                        
                        
                        :
                        
                        <div className='flex flex-row gap-4 rounded-xl items-center justify-center'>
                            
                        {
                            
                            subscribeWeekly.map((sub)=>{
                                return(
                                    <button  
                                        className='h-16 w-28 rounded-xl border-8 border-white px-2 font-mono font-bold text-white'
                                        onClick={()=>{
                                            setSubId(sub.subId)
                                            setShowPaypal(true)
                                        }}
                                        >
                                        {sub.name}
                                    </button>
                                )
                            })
                        }

                        </div>
                    )
                : <></>
                }
                
                {paymentFrequency === "One-off" ? (
                    <>
                        <div className='flex flex-col items-center justify-center'>
                            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent', padding: '10px', border: 'none' }}>
                                <span className='text-3xl text-white'>£</span>
                                <input
                                    className='text-3xl text-white w-full'
                                    type="number"
                                    min="1"
                                    step="0.01"
                                    placeholder="Enter donation amount"
                                    onChange={handleDonationChange}
                                    style={{ border: 'none', backgroundColor: 'transparent' }}
                                />
                            </div>
                            <div className='flex flex-row gap-4 rounded-xl items-center pt-12 justify-center'>
                                {showPaypal ? (
                                    <PayPalScriptProvider key={amount && paymentFrequency} options={{
                                        "clientId": `${process.env.REACT_APP_PAYPAL_APP_CLIENT_ID}`,
                                        "currency": "GBP"
                                    }}>
                                        <PayPalWrapper onScriptLoaded={handleScriptLoad}>
                                            <PayPalButtons
                                                createOrder={createOrder}
                                                onApprove={onApprove}
                                                onError={onError}
                                            />
                                        </PayPalWrapper>
                                    </PayPalScriptProvider>
                                    ) :  
                                    <button className='bg-blue-400 px-4 text-white rounded-xl h-20 text-2xl font-mono font-bold' onClick={handleDonate}>Donate with Paypal</button>
                                }                  
                            </div>
                            
                        </div>
                        </>
                    ) : (
                        null
                    )
                }
            </div>
        </Layout>

    );
};

const PayPalWrapper = ({ onScriptLoaded, children }) => {
    useEffect(() => {
        if (window.paypal) {
            onScriptLoaded();
        }
    }, []);
    
    return <>{children}</>;
};
    
export default Give;
