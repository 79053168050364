import React from 'react';
import Layout from "../components/Layout";

import QRCodeComponent from '../components/QRcode';

const Qr = () => {
  const websiteUrl = "https://give.gracetabernacleinternational.com";

  return (
    <Layout page={"Scan and give"}>
        <div className="flex justify-center items-center h-screen">
            <QRCodeComponent url={websiteUrl} />
        </div>
    </Layout>
    
  );
};

export default Qr;
