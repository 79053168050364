import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeComponent = ({ url }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="md:hidden block">
        <QRCode value={url} size={200} level={"H"} includeMargin={true} />
      </div>
      <div className="hidden md:block">
        <QRCode value={url} size={500} level={"H"} includeMargin={true} />
      </div>
      <p className="mt-2 text-sm text-gray-600">Scan the QR code to visit: {url}</p>
    </div>
  );
};

export default QRCodeComponent;