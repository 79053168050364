import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Home from './pages'; // Import the 'Home' component
import About from './pages/about'; // Import the 'About' component
import Contact from './pages/contact'; // Import the 'Contact' component
import Give from './pages/give'; // Import the 'Give' component
import Gallery from './pages/gallery';
import Qr from './pages/qrcode';

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>

        <Route path="/" element={<Give/>} />
        <Route path="/qrcode" element={<Qr/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/give" element={<Give/>} />
        <Route path='/gallery' element={<Gallery/>} />
      </Routes>
      </Router>
    </div>
  );
}

export default App;
