import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

const DropDownMenu = () => {
  
  return (
    <nav className="grid h-20  flex-col  md:h-24 z-50">
      <div className="flex flex-col w-fit space-y-2 p-2 bg-blue-400 rounded-lg items-start">
        <span className="hover:underline">
          <Link to="https://gti.gracetabernacleinternational.com/">Back</Link>
        </span>
        <span className="hover:underline">
          <Link to="/">Give online</Link>
        </span>
        <span className="hover:underline">
          <Link to="/qrcode">Scan qrcode</Link>
        </span>
        {/* <span className="hover:underline">
          <Link to="/contact">Contact us</Link>
        </span>
        <span className={`hover:underline`}>
          <Link to="/gallery">Gallery</Link>
        </span>
        <span className="hover:underline">
          <Link to="/give">Give</Link>
        </span> */}
      </div>
    </nav>
  );
};

export default DropDownMenu;
