import React from "react";
import DropDownMenu from "./DropDownNav";
import NavBar from "./NavBar";

const Header = (props) => {
  

  const height = `${props.slug === "Home" ? "md:h-[450px]" : "md:h-[500px]"}`;

  return (
    <div>
      <div className="group relative">
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-blue-500 to-blue-500 via-elanco-blue-400 opacity-60"></div>
        <img
          src="download.jpg"
          alt="praying woman"
          className={`z-1 relative h-80 ${height} w-full rounded object-cover`}
        />
  
        <div className="absolute right-0 top-5 transform -translate-x-1/2 z-20 block ">
          <DropDownMenu />
        </div>
        <div className="absolute top-24 md:top-40 z-10 p-5 md:px-20">
          <section className="float-left text-left text-3xl md:text-6xl flex flex-col font-semibold font-mono text-white gap-2">
            {props.slug === "Home" ? (
              ""
            ) : (
              <p className="text-2xl">{props.slug}</p>
            )}
            <p>Grace</p>
            <p>Tabernacle</p>
            <p>International</p>
            <p>Ministries</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Header;
